<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-3>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col xs-12>

                    <v-card v-if="orders.length>0" flat>
                    <v-list rounded>
                        <v-list-group
                            v-for="item in orders"
                            :key="item.code"
                            v-model="item.active"
                            no-action
                        >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-row no-gutters align="center">
                                    <v-col justify cols="7">
                                        <div class="subtitle-1 font-weight-medium">{{ item.name }}</div>
                                        <div class="body-2 font-weight-light">{{ "(" + item.line_items[0].quantity + ") " + item.line_items[0].variation_name}}</div>
                                    </v-col>
                                    <v-col align="right" cols="5">
                                        <div class="body-2 font-weight-light">{{status + " " + getDate(item)}}</div>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </template>
                        <v-list-item>
                            <v-row dense>
                            <v-col cols="12">
                                <SquareReceipt :item=item item-key="name" ref="receipt"
                                    v-bind:order=item
                                    v-bind:showTitle="true"
                                    v-bind:showDetails="true"
                                    :showCode=true
                                />
                            </v-col>
                            </v-row>
                        </v-list-item>
                        </v-list-group>
                    </v-list>
                    </v-card>

                    <div class="text-center">
                        <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import SquareReceipt from '@/components/payment/SquareReceipt'
import moment from 'moment'
import { mapGetters } from 'vuex'
import api from '@/store/api'
export default {
    components: {
        SquareReceipt,
    },
    data () {
        return {
            orderedTitle: "Ordered",
        }
    },
    mounted () {
        api.loadUserOrders()
    },
    computed: {
        ...mapGetters({
            orders: 'getUserOrders',
            loading: 'loading'
        }),
        ordered () {
            return this.orders.filter(function(order){
                return order.status == "Ordered"
            })
        },
    },
    methods: {
        refresh () {
            api.loadUserOrders()
        },
        //TODO
        getDeliveries () {
            return []
        }
    }
}
</script>